export function badgeStaticFormatStyle(value) {
    const TagsDictionary = {
        'creado': 'green',
        'modificado': 'violet',
        'eliminado': 'red',
        'iniciado': '[#6ab1c9]',
        'cierre': 'red',
        'activo': 'sky',
        'nuevo': 'green',
        'inactivo': 'red',
        'estado-de-productos': 'yellow',
        'tipo-pago': 'cyan',
        'tipo-efectivo': 'green',
        'retornable': 'green',
        'no-retornable': 'green',
        'efectivo': 'green',
        'transferencia': 'teal',
        'cheque': 'rose',
        'cerrado': 'red',
        'actualización': 'sky',
    };
    return `text-[#6ab1c9] bg-${TagsDictionary[value]}-400 dark:bg-${TagsDictionary[value]}-700 font-semibold text-xs mr-2 px-2 py-0.5`
}

export function badgeStaticFormatCssStyle(value) {
    let color = '';
    let backgroundColor = '';
    switch (value) {
        case 'creado':
        case 'activo':
        case 'iniciado':
        case 'tipo-efectivo':
        case 'retornable':
        case 'Permitir':
        case 'Si':
        case 'no-retornable':
        case 'efectivo':
            color = '#27AE60';
            backgroundColor = '#6FCF97';
            break;
        case 'modificado':
            color = '#8E44AD';
            backgroundColor = '#BB6BD9';
            break;
        case 'eliminado':
        case 'Denegar':
        case 'No':
        case 'cierre':
        case 'inactivo':
        case 'cerrado':
            color = '#dc2626';
            backgroundColor = '#EB5757';
            break;
        case 'nuevo':
        case 'actualización':
            color = '#3498DB';
            backgroundColor = '#56CCF2';
            break;
        case 'estado-de-productos':
            color = '#F1C40F';
            backgroundColor = '#F2C94C';
            break;
        case 'tipo-pago':
            color = '#00BCD4';
            backgroundColor = '#76E4F7';
            break;
        case 'transferencia':
            color = '#008080';
            backgroundColor = '#2D9CDB';
            break;
        case 'cheque':
            color = '#E91E63';
            backgroundColor = '#F48FB1';
            break;
        default:
            break;
    }
    return {
        backgroundColor: backgroundColor + '30',
        color: color,
    }
}

export function badgeBooleanStyle(value) {
    if (value) {
        return 'text-green-600 dark:text-green-500'
    }
    else {
        return 'text-red-500 dark:text-red-500'
    }
}

// export function badgeBooleanValue(value, options, iconTrue = 'fa-check', iconFalse = 'fa-xmark') {
export function badgeBooleanValue(value, options, iconTrue = '', iconFalse = '') {
    if (value) {
        return <span className='text-center'><i className={`fa-solid ${iconTrue} text-xs`} /> {options[0]}</span>
    }
    else {
        return <span className='text-center'><i className={`fa-solid ${iconFalse} text-xs`} /> {options[1]}</span>
    }
}

export function badgeBooleanStateValue(value, options) {
    if (value) {
        return <span><i className='fa-solid fa-add text-sm' /> {options[0]}</span>
    }
    else {
        return <span><i className='fa-solid fa-minus text-sm' /> {options[1]}</span>
    }
}

export function NoteIconSvg() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' className='bi bi-journal-text h-14 sm:h-20' viewBox='0 0 16 16'>
            <path d='M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z' />
            <path d='M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z' />
            <path d='M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z' />
        </svg>
    )
}

export const colourStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: window.localStorage.getItem('theme') === 'dark' ? 'rgba(31, 41, 55, 255)' : 'white',
        color: window.localStorage.getItem('theme') === 'dark' ? 'rgba(234,179,8, 255)' : 'black',
        border: window.localStorage.getItem('theme') === 'dark' ? '1px solid rgba(255,255,255,.4)' : '1px solid rgba(0,0,0,.2)',
        borderRadius: '7px',
        padding: '2.5px',
        // maxWidth:'100%',
        // fontWeight: '300px',
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
        },
    }),
    input: (styles) => ({
        ...styles,
        color: window.localStorage.getItem('theme') === 'dark' ? 'white' : 'black',
        // maxWidth: '30px',
        textDecoration: 'none',
    }),
    option: (styles, { isDisabled, isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused
                ? window.localStorage.getItem('theme') === 'dark' ? 'rgba(31,41,55,.6)' : 'lightgray'
                : window.localStorage.getItem('theme') === 'dark' ? 'rgba(31,41,55,255)' : 'white',
            color: window.localStorage.getItem('theme') === 'dark' ? 'white' : 'black',
            cursor: isDisabled ? 'not-allowed' : 'default',
        };
    },
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: window.localStorage.getItem('theme') === 'dark' ? 'rgba(31,41,55,255)' : 'lightgray',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: window.localStorage.getItem('theme') === 'dark' ? 'lightgray' : 'rgba(31,41,55,255)',
    }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: window.localStorage.getItem('theme') === 'dark' ? 'black' : 'white',
    }),
    menuList: (styles) => ({
        ...styles,
        padding: '0',
    }),
    singleValue: (styles) => ({
        ...styles,
        color: window.localStorage.getItem('theme') === 'dark' ? 'rgba(255,255,255,.9)' : 'black',
    }),
    placeholder: (styles) => ({
        ...styles,
        color: window.localStorage.getItem('theme') === 'dark' ? 'rgba(255,255,255,.4)' : 'rgba(0,0,0,.4)',
    }),
    indicatorsContainer: (styles) => ({
        ...styles,
        color: 'white',
    }),
};
