import { useState, useEffect } from 'react';
import { requestAuth } from '../http/httpRequest';

const useApi = (url, method = 'GET', body = null) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);

            try {
                await requestAuth(method, url, body)
                    .then((response) => {
                        setData(response.data)
                    })
                    .catch(error => {
                        setError(error)
                    })
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [url, method, body]);

    return { data, loading, error };
};

export default useApi;