import React, { useEffect, useState } from 'react'
import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { MinimalActionButton } from '../../../../components/form/minimalActionButton'
import { Actions } from '../../../../components/form/actions'
import { ModalForm } from '../../../../components/modals/ModalForm'
import { RegistroDeudaEnvaseInmediataValues, TransformarDeudaEnvaseValues } from '../../../../FormSchemes/DeudaEnvaseScheme'
import { DefaultParamsForRecall } from '../../../../utils/defaulStates'
import { UpdateValuesModal } from '../../../../FormSchemes/GeneralFunctions'
import { DeleteValues } from '../../../../FormSchemes/CuadernoHistorialScheme'
import { Callers } from '../../../../hooks/Callers'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const ListDeudaEnvaseTable = ({ globalParams }) => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        // imports
        requestAuthPaginate,
        TableContainer,
        formatDateWithTime,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        RangeDate,
        TableSection,
        ActionSection,
        Section,
        redirect
    } = useGeneralParams()
    const [registrarDeudaInmediataModal, setRegistrarDeudaInmediataModal] = useState(false)
    const [DeleteModal, setDeleteModal] = useState(false)
    const [CurrentData, setCurrentData] = useState(false)
    const [TransformarModal, setTransformarModal] = useState(false)
    const navigate = useNavigate()
    const reloadMainTable = useSelector(state => state.login.reloadMainTable)
    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/deudaEnvases/${globalParams?.tipoDeuda}/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: '$Almacen_producto.Producto_estado.Producto.nombre$' }
        })
    }

    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, reloadMainTable]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, reloadMainTable]);

    const reloadTable = () => {
        Callers(dispatch)
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
    }
    return (
        <Section>
            <ActionSection>
                {
                    <Actions
                        buttons={[
                            {
                                icon: 'minus',
                                label: `Registrar ${globalParams?.tipoDeuda === 'garantía' ? 'garantía directa' : 'préstamo directo'}`,
                                action: () => setRegistrarDeudaInmediataModal(true),
                                className: 'dark:bg-red-700 bg-red-500 text-white'
                            },
                            {
                                icon: 'minus',
                                label: `Registro multiple`,
                                action: () => navigate('reg-multiple'),
                                className: 'dark:bg-teal-700 bg-teal-500 text-white'
                            },
                        ]}
                    />
                }
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    width='6/12'
                    options={[
                        {
                            label: 'Producto',
                            value: '$Almacen_producto.Producto_estado.Producto.nombre$'
                        }
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: 'Estado',
                                value: ''
                            },
                            {
                                label: 'Pendientes',
                                value: 'pendientes'
                            },
                            {
                                label: 'Completados',
                                value: 'completados'
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Encargado',
                            columns: ['envaseEncargadoCodigo', 'envaseEncargadoDetalles'],
                            // icon: 'fa-solid fa-user-circle text-lg',
                            // skipLine: true,
                            className: 'flex items-center',
                            // stickyL: true
                        },
                        {
                            label: 'Deudor',
                            columns: ['envaseDeudorCodigo', 'envaseDeudorDetalles'],
                            // icon: 'fa-solid fa-user-circle text-lg',
                            // skipLine: true,
                            className: 'flex items-center',
                            // stickyL: true
                        },
                        {
                            label: 'Producto',
                            columns: ['producto_nombre'],
                        },
                        {
                            label: 'Cuaderno',
                            columns: ['cuaderno'],
                        },
                        {
                            label: 'Deuda envase inicial',
                            columns: ['saldo_inicial'],
                            numberValue: true,
                            sum: true
                        },
                        {
                            label: 'Deuda envase',
                            columns: ['saldo'],
                            numberValue: true,
                            sum: true
                        },
                        ...(
                            globalParams?.tipoDeuda === 'garantía' ?
                                [
                                    {
                                        label: 'Método de pago',
                                        columns: ['metodo_pago_garantia'],
                                        tag: true
                                    },
                                    {
                                        label: 'Monto inicial Bs.',
                                        columns: ['monto_garantia_inicial'],
                                        numberValue: true,
                                        sumDec: true
                                    },
                                    {
                                        label: 'Saldo Bs.',
                                        columns: ['monto_garantia'],
                                        numberValue: true,
                                        sumDec: true
                                    }
                                ] :
                                []
                        ),
                        {
                            label: 'Estado',
                            columns: ['estado'],
                            boolean: true,
                            booleanOptions: ['Completado', 'Pendiente'],
                            booleanIconTrue: 'fa-check',
                            booleanIconFalse: 'fa-circle'
                        },
                        {
                            label: globalParams?.tipoDeuda === 'garantía' ? 'Fecha' : 'Fecha de préstamo',
                            columns: ['createdAt'],
                            transform: true,
                            func: formatDateWithTime
                        },
                        ...(
                            globalParams?.tipoDeuda === 'garantía' ?
                                []
                                :
                                [{
                                    label: 'Fecha límite',
                                    columns: ['fecha_limite'],
                                    transform: true,
                                    func: formatDateWithTime
                                }]
                        ),
                        {
                            label: 'Observaciones',
                            columns: ['obs'],
                        },
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'cyan',
                                    icon: 'fa-share-from-square',
                                    action: (data) => {
                                        redirect(`${data.id}`)
                                    },
                                    reference: 'id',
                                },
                                {
                                    type: 'green',
                                    icon: 'fa-right-left',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setTransformarModal),
                                    reference: 'id'
                                },
                                {
                                    type: 'delete',
                                    icon: 'fa-trash',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                    reference: 'id'
                                },
                            ],
                        },
                    ]}
                    data={data.data}
                    doubleClickAction={(dataItem) => redirect(`${dataItem.id}`)}
                    checkList={false}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {
                registrarDeudaInmediataModal &&
                <ModalForm
                    setModal={setRegistrarDeudaInmediataModal}
                    label={`Registrar ${globalParams?.tipoDeuda}`}
                    dataValues={RegistroDeudaEnvaseInmediataValues(globalParams?.tipoDeuda)}
                    urlApi={`/deudaEnvaseDirecto`}
                    method={'post'}
                    call={() => {
                        reloadTable()
                        // dispatch(setReloadSubTable())
                        // dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                TransformarModal &&
                <ModalForm
                    setModal={setTransformarModal}
                    label={`Transformar a ${globalParams?.tipoDeuda === 'garantía' ? 'préstamo' : 'garantía'}`}
                    dataValues={TransformarDeudaEnvaseValues(CurrentData.id, globalParams?.tipoDeuda, CurrentData.saldo)}
                    urlApi={`/deuda_envase/transformarDeudaEnvase/${CurrentData.id}`}
                    method={'put'}
                    call={reloadTable}
                    buttonLabel='Guardar'
                />
            }
            {
                DeleteModal &&
                <ModalForm
                    setModal={setDeleteModal}
                    label='Eliminar deuda'
                    dataValues={DeleteValues(CurrentData)}
                    urlApi={`/deudaEnvaseDirecto/${CurrentData.id}`}
                    method={'delete'}
                    call={reloadTable}
                    buttonLabel='Eliminar'
                />
            }
        </Section>
    )
}
