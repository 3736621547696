import React, { useState } from 'react';
import { Formik, Field, Form, FieldArray } from 'formik';
import WindowedSelect from "react-windowed-select";
// import Select from 'react-select'
import * as Yup from 'yup';
import useApi from '../../hooks/useApi';
import { colourStyles } from '../../utils/styleFormat';
import { requestAuth } from '../../http/httpRequest';
import { useDispatch } from 'react-redux';
import { toastOn } from '../../store/slices/toast';
import { DateTimePickerBasic } from '../datePicker/DateTimePicker';

const validationSchema = Yup.object().shape({});

const metodosPago = [
    { label: 'Efectivo', value: 'efectivo' },
    { label: 'Transferencia', value: 'transferencia' },
    { label: 'Cheque', value: 'cheque' }
];

const initialValues = {
    encargado_id: '',
    entries: Array(5).fill({
        user_id: '',
        almacen_producto_id: '',
        metodo_pago_garantia: 'efectivo',
        saldo: 0,
        monto_garantia: 0,
        createdAt: new Date(),
        fecha_limite: null
    }),
};
const InputSelect = React.memo(({ name, options, setFieldValue, index, fieldDependency, value }) => {
    const currentValue = options.find(option => option.value === (value || '')) || null;

    const handleChange = (selectedOption) => {
        setFieldValue(`entries[${index}].${name}`, selectedOption ? selectedOption.value : '');
    };

    return (
        <WindowedSelect
            key={`select-${name}-${index}-${fieldDependency}`}
            options={options}
            value={currentValue}
            onChange={handleChange}
            className="input-select"
            styles={colourStyles}
            isClearable
        />
    );
});

const FormMultiParams = ({ globalParams }) => {
    const { tipoDeuda } = globalParams;
    const [ProdAlms, setProdAlms] = useState([]);
    const { data: responsables = [] } = useApi('/users/empleados', 'GET');
    const { data: deudores = [] } = useApi('/users/clientes', 'GET');
    const dispatch = useDispatch();

    const handleSubmit = async (values, { resetForm }) => {
        try {
            let entriesToProcess = tipoDeuda === 'garantía' ? values.entries.filter(e =>
                e.almacen_producto_id !== '' &&
                e.metodo_pago_garantia !== '' &&
                e.monto_garantia > 0 &&
                e.saldo > 0
            )
                :
                values.entries.filter(e =>
                    e.almacen_producto_id !== '' &&
                    e.saldo > 0
                );

            if (entriesToProcess.length === 0) {
                dispatch(toastOn({ type: 'warning', message: 'No hay registros válidos para procesar' }));
                return;
            }

            let allSuccess = true;

            for (const element of entriesToProcess) {
                const body = {
                    ...element,
                    encargado_id: values.encargado_id,
                    metodo_intercambio: tipoDeuda
                };

                try {
                    const response = await requestAuth('post', '/deudaEnvaseDirecto', body);
                    dispatch(toastOn({ type: 'success', message: response.data.message }));
                } catch (error) {
                    allSuccess = false;
                    dispatch(toastOn({
                        type: 'danger',
                        message: error?.response?.data?.message ?? 'Error al procesar un registro'
                    }));
                }
            }

            if (allSuccess) {
                resetForm({
                    values: {
                        ...initialValues,
                        encargado_id: values.encargado_id
                    }
                });
                dispatch(toastOn({ type: 'success', message: 'Todos los registros se procesaron correctamente' }));
            }

        } catch (error) {
            dispatch(toastOn({ type: 'danger', message: 'Error general al procesar el formulario' }));
        }
    };

    const callProdAlms = async (url) => {
        try {
            const response = await requestAuth('GET', url);
            setProdAlms(response.data.map(x => ({ label: x.etiqueta, value: x.id })));
        } catch (error) {
            setProdAlms([]);
            dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message ?? 'Error al cargar productos!' }));
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <h2 className="text-2xl font-semibold dark:text-gray-200">REGISTRAR {tipoDeuda === 'préstamo' ? 'PRESTAMO DIRECTO' : 'GARANTÍA DIRECTA'}</h2>
                    <EncargadoSelect
                        responsables={responsables}
                        setFieldValue={setFieldValue}
                        values={values}
                        callProdAlms={callProdAlms}
                        setProdAlms={setProdAlms}
                    />

                    <FieldArray name="entries">
                        {(fieldArrayHelpers) => (
                            <EntriesTable
                                values={values}
                                setFieldValue={setFieldValue}
                                ProdAlms={ProdAlms}
                                deudores={deudores}
                                fieldArrayHelpers={fieldArrayHelpers}
                                tipoDeuda={tipoDeuda}
                            />
                        )}
                    </FieldArray>
                </Form>
            )}
        </Formik>
    );
};

const EncargadoSelect = ({ responsables, setFieldValue, values, callProdAlms, setProdAlms }) => {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleChangeResponsable = (selectedOption) => {
        const resetEntries = initialValues.entries.map(entry => ({
            ...entry,
            metodo_pago_garantia: 'efectivo',
            saldo: 0,
            monto_garantia: 0,
            createdAt: new Date(),
            fecha_limite: null
        }));

        setSelectedOption(selectedOption);
        setFieldValue("encargado_id", selectedOption ? selectedOption.value : '');
        setFieldValue("entries", resetEntries);

        if (selectedOption) {
            callProdAlms(`/user/${selectedOption.value}/almacenes/vacios`);
        } else {
            setProdAlms([]);
        }
    };

    return (
        <div className="mb-4">
            <label className="block text-sm font-semibold dark:text-gray-200">Responsable</label>
            <WindowedSelect
                name="encargado_id"
                options={responsables?.map(x => ({ label: x.etiqueta, value: x.id })) ?? []}
                value={selectedOption}
                onChange={handleChangeResponsable}
                className="input-select"
                styles={colourStyles}
                isClearable
            />
        </div>
    );
};
const EntriesTable = ({ values, setFieldValue, ProdAlms, deudores, tipoDeuda }) => {
    return (
        <FieldArray name="entries">
            {(arrayHelpers) => (
                <div className="table-container">
                    <div className='pl-5 overflow-x-auto min-h-96'>
                        <table className="excel-table w-full">
                            <thead>
                                <tr>
                                    <Thead label='' />
                                    <Thead label='Fecha' />
                                    <Thead label='Deudor' />
                                    <Thead label='Producto' />
                                    {
                                        tipoDeuda === 'garantía' &&
                                        <>
                                            <Thead label='Método Pago' />
                                            <Thead label='Garantía' />
                                        </>
                                    }
                                    {
                                        tipoDeuda === 'préstamo' &&
                                        <Thead label='Fecha límite' />
                                    }
                                    <Thead label='Cantidad' />
                                </tr>
                            </thead>
                            <tbody>
                                {values.entries.map((entry, index) => (
                                    <tr key={index} className='relative'>
                                        <td className='absolute -left-5 top-1.5 flex items-center justify-center'>
                                            <i onClick={() => arrayHelpers.remove(index)} className='fa fa-circle-minus text-xl text-center text-red-600 cursor-pointer' />
                                        </td>
                                        <td className=''>
                                            <DateTimePickerBasic
                                                name={`entries[${index}].createdAt`}
                                                value={values.entries[index].createdAt}
                                                setValues={setFieldValue}
                                                required={true}
                                                // onChange={(e) => {
                                                //     handleChange(e)
                                                //     // setValues(values[field.name], e)
                                                //     // console.log(e)
                                                // }}
                                                onChange={(date) => setFieldValue(`entries[${index}].createdAt`, date)}
                                            />
                                            {/* <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-gray-300 rounded-r-lg dark:bg-gray-600 dark:text-gray-400 dark:border-gray-500'>
                                                                {field.subData}
                                                            </span> */}
                                        </td>
                                        <td className='w-1/4'>
                                            <InputSelect
                                                name="user_id"
                                                options={deudores?.map(x => ({ label: x.etiqueta, value: x.id })) ?? []}
                                                setFieldValue={setFieldValue}
                                                index={index}
                                                value={entry.user_id}
                                            />
                                        </td>
                                        <td className='w-1/4'>
                                            <InputSelect
                                                name="almacen_producto_id"
                                                options={ProdAlms}
                                                setFieldValue={setFieldValue}
                                                index={index}
                                                value={entry.almacen_producto_id}
                                            />
                                        </td>
                                        {
                                            tipoDeuda === 'garantía' &&
                                            <>
                                                <td className='w-1/4'>
                                                    <InputSelect
                                                        name="metodo_pago_garantia"
                                                        options={metodosPago}
                                                        setFieldValue={setFieldValue}
                                                        index={index}
                                                        value={entry.metodo_pago_garantia}
                                                    />
                                                </td>
                                                <td className='w-1/4'>
                                                    <Field
                                                        type="number"
                                                        name={`entries[${index}].monto_garantia`}
                                                        className={fieldClassName()}
                                                        step='0.1'
                                                    />
                                                </td>
                                            </>
                                        }
                                        {
                                            tipoDeuda === 'préstamo' &&
                                            <td className='w-1/4'>
                                                <DateTimePickerBasic
                                                    name={`entries[${index}].fecha_limite`}
                                                    value={values.entries[index].fecha_limite}
                                                    setValues={setFieldValue}
                                                    required={false}
                                                    onChange={(date) => setFieldValue(`entries[${index}].fecha_limite`, date)}
                                                />
                                            </td>
                                        }
                                        <td className='w-1/4'>
                                            <Field
                                                type="number"
                                                name={`entries[${index}].saldo`}
                                                className={fieldClassName()}
                                                min='0'
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="buttons flex flex-col">
                        <span
                            className='bg-green-600 dark:bg-green-700 flex w-min mt-2 px-2 py-1 rounded text-white items-center gap-2 cursor-pointer'
                            onClick={() => arrayHelpers.push({
                                user_id: '',
                                almacen_producto_id: '',
                                saldo: 0,
                                metodo_pago_garantia: 'efectivo',
                                monto_garantia: 0,
                                createdAt: new Date()
                            })}
                        >
                            <i className='fa fa-plus' />
                            Adicionar
                        </span>
                        <button
                            type='submit'
                            className='
                            text-white
                            focus:ring-4
                            focus:outline-none
                            focus:ring-yellow-300
                            rounded-lg
                            text-sm
                            px-5 py-2.5
                            text-center
                            bg-red-600 hover:bg-red-700
                            dark:bg-red-700 dark:hover:bg-red-600 disabled:bg-gray-600 dark:disabled:bg-gray-600 disabled:opacity-50
                            dark:focus:ring-red-800
                            transition-all
                            duration-150
                            w-full
                            mt-20
                            '
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            )
            }
        </FieldArray >
    );
};

const fieldClassName = () => {
    return `shadow-sm outline-none text-end 
    [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none 
    [&::-webkit-inner-spin-button]:appearance-none border border-gray-300 text-gray-900 
    text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 
    dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:bg-gray-800
    dark:focus:ring-blue-500 dark:focus:border-blue-500 font-semibold 
    disabled:opacity-55 disabled:text-gray-500 dark:disabled:text-gray-200`
}

const Thead = ({ label }) => {
    return <th className='text-left font-semibold text-sm dark:text-gray-200'>
        {label}
    </th>
}

export default FormMultiParams;